.keyFieldSelector {
  display: flex !important;
  flex-direction: column;
  height: 60px;
  justify-content: space-around !important;
  flex-wrap: wrap;
  align-items: flex-start !important;
}

.keyFieldSelector :global(.KeyFieldContainer) {
  overflow: hidden;
  max-width: 215px;
  text-overflow: ellipsis;
}

.keyFieldSelector :last-child {
  margin-left: 0 !important;
}

.keepDataFromLastWrapper {
  margin-top: 15px;
}

.descriptionsFieldName,
.partitioningFieldName {
  margin-bottom: 10px;
}

.descriptionsCheckboxesWrapper {
  display: flex;
  justify-content: space-around;
}

.hierarchicalDimensionsAddWrapper,
.dataRecyclingFieldNameWrapper,
.descriptionsFieldNameWrapper,
.partitioningFieldNameWrapper,
.keepDataFromLastWrapper {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #333333;
}

.dataRecyclingPeriodWrapper,
.descriptionsFieldName,
.partitioningFieldName,
.dataRecyclingFieldName {
  margin-top: 10px;
}

.dataRecyclingPeriodWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}

.dataRecyclingPeriod {
  width: 65px !important;
  height: unset !important;
  margin-bottom: unset !important;
}

.dataRecyclingType {
  width: 200px;
}

.dataRecyclingAccordionTitle,
.dataPartitioningAccordionTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dataRecyclingAccordion :global(.AccordionTitle .AccordionDescription) {
  width: 100%;
}

.hierarchicalDimensionsAddWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 10px;
}

.hierarchicalDimensionsAdd {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  border-radius: 15px;
  transition: background-color 0.5s;
}

.hierarchicalDimensionsAdd:hover {
  cursor: pointer;
  background-color: #8080804f;
}

.hierarchicalEmptyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  height: 100px;
  justify-content: space-between;
}

.hierarchicalListWrapper {
  padding: 24px 0 24px 0;
  border-radius: 8px;
  background: #ffffff;
}

.hierarchicalListWrapper:not(:global(.empty-list)) {
  height: 300px;
  overflow-y: scroll;
  padding: 5px 0 5px 0;
}

.noneHierarchicalDimensionsText {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #555555;
}

.createHierarchicalDimension {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #337dff;
}

.createHierarchicalDimension:hover {
  cursor: pointer;
}

.infoIcon {
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #005dff;
  border: 10px solid #e6efff;
  border-radius: 20px;
  background-color: #e6efff;
}

.GoBackButton {
  background-color: #555555;
}

.ButtonsWrapper {
  display: flex;
  position: absolute;
  right: 62px;
  bottom: 2vh;
  z-index: 100;
  gap: 22px;
}

.NextStepButton,
.GoBackButton {
  width: 150px;
}

.draggableIcon {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  display: flex;
  overflow: hidden;
  line-height: 37px;
  padding-left: 10px;
  font-size: 12px;
  color: #373737;
  font-weight: 500;
  opacity: 0.8;
  align-items: center;
}

.draggableIcon :first-child {
  font-size: 20px;
}

.labelStyles {
  height: 100%;
  display: flex;
  align-items: center;
}

.draggableTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 30px;
  width: 10%;
}

.fieldTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 30px;
  width: 75px;
}

.actionTd {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 30px;
  width: 40px;
}

.FinalizeButtonLabel {
  overflow-x: hidden;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.keyRecyclingAccordionList {
  height: 84vh !important;
}